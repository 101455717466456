import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "body_wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_carousel_section = _resolveComponent("carousel-section")!
  const _component_topics_section = _resolveComponent("topics-section")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.category)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_header_section, {
            image: _ctx.category.image,
            title: _ctx.category.title,
            description: _ctx.category.description
          }, null, 8, ["image", "title", "description"]),
          _createVNode(_component_carousel_section, {
            categories: _ctx.categories,
            onOnSelect: _ctx.onSelect
          }, null, 8, ["categories", "onOnSelect"]),
          _createVNode(_component_topics_section, {
            id: _ctx.category.id,
            topicCount: _ctx.category.topicCount,
            showAlphabet: _ctx.category.showAlphabet
          }, null, 8, ["id", "topicCount", "showAlphabet"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_loader, { show: _ctx.isLoading }, null, 8, ["show"])
  ], 64))
}