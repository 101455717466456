
import { PropType, defineComponent } from "vue";
import { getTopicLevelLabel } from "@/utils/topics";
import { TopicLevel } from "@toppick/common/build/interfaces";
import "./styles.css";
export default defineComponent({
  props: {
    title: String,
    description: String,
    image: String,
    level: {
      type: String as PropType<TopicLevel>,
      required: true,
    },
  },

  computed: {
    levelLabel() {
      return getTopicLevelLabel(this.level);
    },

    topicLevelClass() {
      switch (this.level) {
        case TopicLevel.Easy:
          return "smallBadge";
        case TopicLevel.Medium:
          return "mediumBadge";
        case TopicLevel.Hard:
          return "hardBadge";
        default:
          return "";
      }
    },
  },
});
