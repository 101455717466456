
import { defineComponent } from "vue";
import { getTopicsCount } from "@toppick/common/build/api/topic";
import {
  getCategories,
  getCategoryDetails,
} from "@toppick/common/build/api/category";
import { Category, CategoryFeatured } from "@toppick/common/build/interfaces";
import HeaderSection from "./HeaderSection.vue";
import TopicsSection from "./TopicsSection.vue";
import CarouselSection from "./CarouselSection.vue";
import Loader from "@/components/ui/Loader.vue";
import { DEFAULT_CATEGORY } from "@/constants/categories";
import "./styles.css";
import { parseCategoriesSlug, parsePage } from "@/utils/ui";
const MAX_LOADED_CATEGORIES = 20;
export default defineComponent({
  components: { HeaderSection, TopicsSection, CarouselSection, Loader },
  data() {
    return {
      isLoading: true,
      category: null as Category | null,
      categories: [] as CategoryFeatured[],
    };
  },
  methods: {
    async loadCategory(slug: string) {
      this.category = await getCategoryDetails({
        slug,
        count_topics: true,
      });
    },

    async loadCategoryAll() {
      DEFAULT_CATEGORY.topicCount = await getTopicsCount({});
      this.category = DEFAULT_CATEGORY;
    },

    onSelect(slug: string) {
      this.$router.replace({ params: { slug }, query: { page: 1 } });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    currentSlug(): string {
      return parseCategoriesSlug(this.$route.params.slug as string);
    },
  },
  async mounted() {
    try {
      this.categories = await getCategories({
        limit: MAX_LOADED_CATEGORIES,
      });
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;

    this.$watch(
      () => ({
        slug: this.currentSlug,
      }),
      async ({ slug }: { slug: string }) => {
        try {
          if (slug === "all") {
            this.loadCategoryAll();
          } else {
            this.loadCategory(slug);
          }
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true }
    );
  },
});
