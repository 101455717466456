import { TopicLevel } from "@toppick/common/build/interfaces";

export function getTopicLevelLabel(level: TopicLevel): string {
  switch (level) {
    case TopicLevel.Easy:
      return "Easy";
    case TopicLevel.Medium:
      return "Medium";
    case TopicLevel.Hard:
      return "Hard";
    default:
      return "";
  }
}
