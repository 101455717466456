
import { defineComponent } from "@vue/runtime-core";
const ALPHABET = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "v",
  "w",
  "y",
  "z",
];
export default defineComponent({
  emits: ["onClick"],
  props: {
    selectedLetter: String,
  },
  data() {
    return {
      ALPHABET,
    };
  },
  methods: {
    onClick(letter: string) {
      this.$emit("onClick", letter);
    },
  },
});
