<template>
  <section
    class="space cmn_hero_banner inner_cmn_hero blog_sec cmn_header_banner"
    v-bind:style="{ 'background-image': 'url(' + image + ')' }"
  >
    <div class="container">
      <div class="banner_inner_wrap">
        <h1>{{ title }}</h1>
        <p>
          {{ description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    description: String,
  },
};
</script>
