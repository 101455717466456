import { Category } from "@toppick/common/build/interfaces";

export const DEFAULT_CATEGORY_TITLE = `Explore Our Library`;

export const DEFAULT_CATEGORY_DESCRIPTION = `Select  a category and find the most relevant topics. You can think of categories as folders that contain a list of pertinent topics, which is exactly what you need to have a meaningful conversation.
Categorizing the topics allows us to better organize and sort out the content available, making it more accessible to our viewers. So pick a category and get started!
`;

export const CATEGORY_ALL_TITLE = `Explore Our Topics`;

export const DEFAULT_CATEGORY: Category = {
  title: DEFAULT_CATEGORY_TITLE,
  slug: "all",
  id: -1,
  description: DEFAULT_CATEGORY_DESCRIPTION,
  image: require("@/assets/images/categories_all.jpeg"),
  topicCount: 0,
  showAlphabet: true,
};
