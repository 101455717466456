
import { defineComponent } from "vue";
import TopicCardPrimary from "@/components/topic/TopicCardPrimary.vue";
import { TopicFeatured } from "@toppick/common/build/interfaces";
import Pagination from "@/components/ui/Pagination.vue";
import { TopicPageTab } from "@/interfaces/ui";
import { getTopics, getTopicsCount } from "@toppick/common/build/api/topic";
import { countPages, parsePage } from "@/utils/ui";
import AlphabetPagination from "@/components/category/AlphabetPagination.vue";

const RESULTS_PER_PAGE = 9;

export default defineComponent({
  components: { TopicCardPrimary, Pagination, AlphabetPagination },
  props: {
    id: {
      type: Number,
      required: true,
    },
    topicCount: {
      type: Number,
      required: true,
    },
    showAlphabet: {
      type: Boolean,
    },
  },
  data() {
    return {
      topics: [] as TopicFeatured[],
      selectedLetter: "",
      currentTopicCounter: this.topicCount,
    };
  },
  methods: {
    onClickTopic(slug: string) {
      this.$router.push({
        name: "Topic",
        params: { slug, tab: TopicPageTab.QUESTION },
      });
    },
    async loadData() {
      const skip = (this.currentPage - 1) * RESULTS_PER_PAGE;
      this.topics = await getTopics({
        skip,
        limit: RESULTS_PER_PAGE,
        category_id: this.id,
        title_starts_with: this.selectedLetter,
      });
    },
    setPage(page: number) {
      this.$router.replace({
        query: { ...this.$route.query, page },
      });
    },
    async onToggleLetter(letter: string) {
      if (letter === this.selectedLetter) {
        this.selectedLetter = "";
      } else {
        this.selectedLetter = letter;
      }
      this.currentTopicCounter = await getTopicsCount({
        start_with_letter: this.selectedLetter,
      });
      this.setPage(1);
    },
  },
  computed: {
    totalPages(): number {
      return countPages(this.currentTopicCounter, RESULTS_PER_PAGE);
    },
    currentPage(): number {
      return parsePage(this.$route.query.page);
    },
  },

  async mounted() {
    this.$watch(
      () => ({
        id: this.id,
        page: this.currentPage,
        letter: this.selectedLetter,
      }),
      async () => {
        try {
          await this.loadData();
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true }
    );
  },
});
