import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "pagination alphabets justify-content-center flex-wrap",
  style: {"margin-top":"30px"}
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ALPHABET, (letter) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "page-item",
        key: letter
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(["page-link montSB text-uppercase mr-0", { active: letter == _ctx.selectedLetter }]),
          onClick: ($event: any) => (_ctx.onClick(letter))
        }, _toDisplayString(letter), 11, _hoisted_2)
      ]))
    }), 128))
  ]))
}